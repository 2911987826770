<template>
  <div class="annex" v-loading="loading">
    <div v-if="introduce == 'project'" class="project-jincome-top">
      <div>
        项目名称：
        <span class="project-jincome-fw">{{ projectInformation.projectName }}</span>
      </div>
      <div v-if="projectInformation.requireAccomplishDate">
        项目截止时间：<span class="project-jincome-fw">{{
          projectInformation.requireAccomplishDate | dateFormat
        }}</span>
        {{ isExceed ? '超出' : '剩余' }}：<span
          :class="
            isExceed
              ? 'deadline-color-red project-jincome-fw'
              : 'deadline-color-green project-jincome-fw'
          "
          >{{ deadline }}</span
        >天
      </div>
    </div>
    <div class="footer-statistics">
      <span class="footer-statistics-total">合计：</span>
      <span
        >实际合同金额： <span style="color: #00ff00">{{ agreement | thousands }}</span></span
      >
      <span class="footer-statistics-ccenter"
        >已收票金额： <span style="color: #4846a5"> {{ Invoiced | thousands }}</span></span
      >
      <span class="footer-statistics-ccenter"
        >已付款金额： <span style="color: #0c7605"> {{ payment | thousands }}</span></span
      >
      <span class="footer-statistics-ccenter"
        >已收款未付款：
        <span style="color: #ef0808"> {{ InvoicedUnpaymented | thousands }}</span></span
      >
      <span class="footer-statistics-ccenter"
        >未收票已付款：
        <span style="color: #e5aa25"> {{ notInvoicedPayment | thousands }}</span></span
      >
      <span class="footer-statistics-ccenter"
        >剩余应付金额：
        <span style="color: #ef2969"> {{ notInvoicedMoney | thousands }}</span></span
      >
    </div>
    <el-row style="margin-bottom: 10px; color: #303133"
      >金额单位：<span style="color: red"> 元</span>；
    </el-row>
    <!-- 修改后的按钮样式和位置 -->
    <div class="operation-container">
      <el-button
        type="primary"
        size="small"
        icon="el-icon-plus"
        @click="addPayment"
        class="add-payment-btn"
      >
        增加付款
      </el-button>
    </div>
    <el-table
      :data="tableData"
      border
      style="width: 100%"
      row-key="id"
      header-cell-class-name="tableTwoClassName"
    >
      <el-table-column type="index" label="序号" width="50" align="center" />
      <el-table-column prop="receiptAmount" label="收票金额" align="right">
        <template slot-scope="scope">
          {{ scope.row.receiptAmount | thousands }}
        </template>
      </el-table-column>
      <el-table-column prop="receiptDate" label="收票日期" align="center">
        <template slot-scope="scope">
          {{ scope.row.receiptDate | dateFormat }}
        </template>
      </el-table-column>
      <el-table-column prop="paymentAmount" label="付款金额" align="right">
        <template slot-scope="scope">
          {{ scope.row.paymentAmount | thousands }}
        </template>
      </el-table-column>
      <el-table-column prop="paymentDate" label="付款日期" align="center">
        <template slot-scope="scope">
          {{ scope.row.paymentDate | dateFormat }}
        </template>
      </el-table-column>

      <!-- <el-table-column prop="remainPaymentAmount" label="剩余付款金额" align="right">
        <template slot-scope="scope">
          {{ scope.row.remainPaymentAmount | thousands }}
        </template>
      </el-table-column> -->
      <el-table-column prop="remark" label="备注" />
      <el-table-column label="操作">
        <template slot-scope="scope">
          <!-- 费用详情 -->
          <el-button size="mini" type="primary" @click="onOutsourceDetail(scope.row)"
            >费用详情</el-button
          >
          <el-button size="mini" type="primary" @click="handleEdit(scope.row)">编辑</el-button>
          <el-button size="mini" type="danger" @click="handleDelete(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      :title="dialogType === 'add' ? '新增付款' : '编辑付款'"
      :visible.sync="dialogVisible"
      append-to-body
      width="750px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      class="payment-dialog"
    >
      <el-form
        :model="formData"
        :rules="addFormRules"
        ref="formRef"
        label-width="100px"
        class="payment-form"
      >
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="收票金额" prop="receiptAmount">
              <el-input v-model="formData.receiptAmount" placeholder="请输入收票金额">
                <template slot="append">元</template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="收票日期" prop="receiptDate">
              <el-date-picker
                v-model="formData.receiptDate"
                type="date"
                placeholder="选择日期"
                style="width: 100%"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <!-- 付款金额 -->
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="付款金额" prop="paymentAmount">
              <el-input v-model="formData.paymentAmount" placeholder="请输入付款金额">
                <template slot="append">元</template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="付款日期" prop="paymentDate">
              <el-date-picker
                v-model="formData.paymentDate"
                type="date"
                placeholder="选择日期"
                style="width: 100%"
              />
            </el-form-item>
          </el-col>
        </el-row>

        <!-- <el-form-item label="剩余付款" prop="remainPaymentAmount">
          <el-input v-model.number="formData.remainPaymentAmount" placeholder="请输入剩余付款金额">
            <template slot="append">元</template>
          </el-input>
        </el-form-item> -->

        <el-form-item label="备注" prop="remark">
          <el-input
            type="textarea"
            :rows="3"
            v-model="formData.remark"
            placeholder="请输入备注信息"
          />
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" :loading="submitLoading" @click="handleSubmit">确 定</el-button>
      </div>
    </el-dialog>

    <!-- 单据详情 -->
    <CostDetailDialog
      v-if="outsoureceDetailDialog"
      :costDialog.sync="outsoureceDetailDialog"
      :options="outsoureceDetailInfo"
      @returnDialogFn="outsoureceDetailDialog = false"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  components: {
    CostDetailDialog: () => import('@/components/costDetailDialog.vue'),
  },
  props: {
    options: {
      type: Object,
      default: function () {
        return {}
      },
    },
    introduce: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      loading: false,
      submitLoading: false,
      tableData: [],
      dialogVisible: false,
      dialogType: 'add',
      formData: {},
      addFormRules: {
        paymentAmount: [{ required: false, message: '请输入付款金额', trigger: 'blur' }],
        paymentDate: [{ required: false, message: '请选择付款日期', trigger: 'change' }],
        receiptAmount: [{ required: false, message: '请输入收票金额', trigger: 'blur' }],
        receiptDate: [{ required: false, message: '请选择收票日期', trigger: 'change' }],
        remainPaymentAmount: [{ required: false, message: '请输入剩余付款金额', trigger: 'blur' }],
      },
      outsoureceDetailInfo: {},
      outsoureceDetailDialog: false,
    }
  },
  computed: {
    ...mapState({
      projectInformation: state => state.project.projectInformation,
      originalForm: state => state.project.originalForm,
    }),
    agreement() {
      //实际合同金额 取合同基本信息实际合同金额
      return this.originalForm?.agreementInformation?.actualContractAmount || 0
    },
    Invoiced() {
      //已收票金额
      let num = 0
      this.tableData.forEach(v => {
        num += Number(v.receiptAmount)
      })
      return num
    },
    payment() {
      let num = 0
      this.tableData.forEach(v => {
        num += Number(v.paymentAmount)
      })
      return num
    },
    InvoicedUnpaymented() {
      //已收票未付款 = 已收票金额 - 已付款金额
      let num = 0
      num = this.Invoiced - this.payment
      return num
    },
    notInvoicedPayment() {
      //未收票已付款
      let num = 0
      num = this.payment - this.Invoiced
      return num
    },
    notInvoicedMoney() {
      // 剩余应付金额 = 实际合同金额 — 已付款金额
      let num = 0
      num = this.agreement - this.payment
      return num
    },
    // 项目截止时间
    deadline() {
      let timestamp = this.projectInformation.requireAccomplishDate // 项目要求完结时间
      // 获取当前日期，设置为00:00:00以去掉时分秒
      const currentDate = new Date()
      currentDate.setHours(0, 0, 0, 0) // 将当前时间设置为00:00:00
      const currentTimestamp = currentDate.getTime() // 当前日期的时间戳（毫秒）
      // 转换为天数
      let daysDifference = (timestamp - currentTimestamp) / (1000 * 60 * 60 * 24)
      daysDifference = Math.floor(daysDifference)
      if (daysDifference > 0) {
        this.isExceed = false
      } else {
        this.isExceed = true
      }
      return daysDifference
    },
    dateFormat(val) {
      if (val) {
        return new Date(val).toLocaleDateString()
      } else {
        return ''
      }
    },
    isPermissionEdit() {
      if (this.originalForm?.agreementInformation?.isPermissionEdit) {
        return true
      } else {
        return false
      }
    },
  },
  watch: {
    originalForm: {
      immediate: true, //初始化立即执行
      deep: true, //对象深度监测
      handler: function (newVal) {
        if (this.permission(['AGREEMENT_INCOME_EDIT'])) {
          this.disabled = true
        } else {
          this.disabled = false
        }
        if (newVal.agreementPaymentList) {
          this.tableData = newVal.agreementPaymentList.deepClone()
        }
      },
    },
  },
  filters: {
    thousands(a) {
      if (a) {
        a = Number(a).toLocaleString()
        return a
      } else {
        return 0
      }
    },
    transitionType(val, arr) {
      let str = ''
      if (arr && arr.length > 0 && val) {
        const item = arr.find(v => v.vatType == val)
        if (item) {
          str = item.vatName
        }
      }
      return str
    },
    transitionData(val, arr) {
      let str = ''
      if (arr && arr.length > 0 && val) {
        const item = arr.find(v => v.vatData == val)
        str = item?.vatName
      }
      return str
    },
  },
  methods: {
    onOutsourceDetail(row) {
      const obj = {}
      if (row.expenseType == 'PROJECT_PAYMENT_APPLICATION') {
        obj.projectPaymentApplicationId = row.expenseId
        obj.costType = '项目付款申请'
      } else if (row.expenseType == 'PROJECT_EXPENSE') {
        obj.projectExpenseId = row.expenseId
        obj.costType = '项目报销'
      }
      this.outsoureceDetailInfo = obj
      this.outsoureceDetailDialog = true
    },
    addPayment() {
      this.dialogType = 'add'
      this.formData = {}
      this.dialogVisible = true
      this.$refs.formRef.resetFields()
    },
    handleEdit(row) {
      this.dialogType = 'edit'
      this.formData = { ...row }
      this.dialogVisible = true
      this.$refs.formRef.resetFields()
    },
    handleDelete(row) {
      this.$confirm('确认删除该付款记录?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.$api.agreementPayment
          .del(row.id)
          .then(() => {
            this.$message.success('删除成功')
            const index = this.tableData.findIndex(item => item.id === row.id)
            this.tableData.splice(index, 1)
          })
          .catch(() => {
            this.$message.error('删除失败')
          })
      })
    },
    handleSubmit() {
      // 检查this.formData所有金额，必须有一个不为空
      if (
        !this.formData.paymentAmount &&
        !this.formData.receiptAmount &&
        !this.formData.remainPaymentAmount
      ) {
        this.$message.warning('请输入至少一个金额')
        return
      }
      this.$refs.formRef.validate(valid => {
        if (!valid) return

        const isAdd = this.dialogType === 'add'
        this.submitLoading = true

        const api = isAdd
          ? this.$api.agreementPayment.add({ ...this.formData, agreementId: this.options.id })
          : this.$api.agreementPayment.edit(this.formData)

        api
          .then(res => {
            this.submitLoading = false
            this.dialogVisible = false
            this.$emit('getData')
            this.$message.success(`${isAdd ? '添加' : '编辑'}成功`)

            if (isAdd) {
              this.tableData.push(res.data)
            } else {
              const index = this.tableData.findIndex(item => item.id === this.formData.id)
              this.tableData.splice(index, 1, res.data)
            }

            if (isAdd) {
              this.$refs.formRef.resetFields()
            }
          })
          .catch(err => {
            this.submitLoading = false
            this.$message.error(`${isAdd ? '添加' : '编辑'}失败`)
            console.log(err)
          })
      })
    },
  },
}
</script>

<style scoped lang="scss">
@import '@/styles/config.scss';
.annex {
  /deep/.el-card {
    //width:50%;
    .annex-text {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    + .el-card {
      margin-top: 20px;
    }
    .el-card__header {
      padding: 10px 15px;
      font-size: 16px;
      font-weight: bold;
    }
  }
  //冲红高亮
  /deep/.tableRowClassName {
    background-color: #fce3e3;
    // color: red;
  }
  // 表头颜色
  /deep/ .tableOneClassName th {
    background: #ddeeff;
  }

  /deep/ .tableTwoClassName th {
    background: #edf3b7cc;
  }

  /deep/ .tableThreeClassName th {
    background: #cbfad9;
  }
  .leftTable {
    margin-left: 30px;
  }
  .colorTable {
    display: inline-block;
    width: 30px;
    height: 10px;
  }
  .tableOneClassName {
    background: #ddeeff;
  }
  .tableTwoClassName {
    background: #edf3b7cc;
  }
  .tableThreeClassName {
    background: #cbfad9;
  }
  /deep/ .undefined {
    border-bottom: 0px;
  }
  /deep/.el-table__cell {
    border-left: 1px solid #ebeef5 !important;
  }

  /deep/.el-table--mini {
    .el-table__cell {
      height: 32px;
    }
    .cell {
      padding: 0;
    }
  }
}
.fj-box {
  display: flex;
  justify-content: space-evenly;
  .fj-text {
    //超出一行省略号
    white-space: nowrap; //禁止换行
    display: inline-block;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis; //...
  }
}
#editDaalog,
#PaymentIsShow {
  /deep/.el-dialog__body {
    padding: 0px 10px;
    .el-form-item {
      margin-bottom: 12px;
    }
  }
}
.footer-statistics {
  display: flex;
  flex-wrap: wrap;
  line-height: 44px;
  margin-top: 15px;
  .footer-statistics-total {
    color: #000;
    font-weight: 700;
  }
  .footer-statistics-ccenter {
    margin-left: 40px;
  }
}
.dialogContentHeight {
  height: 250px;
  overflow: auto;
  white-space: pre-wrap;
  padding: 0px 20px;
}
.operate {
  padding: 5px;
  color: #fff;
}
.emove_Bgc {
  background-color: #f78888;
}
.Edit_Bgc {
  background-color: #e6a23c;
}
.blush_Bgc {
  background-color: #6391f3c2;
}
.proceeds_Bgc {
  background-color: #85ce61c2;
}
.addProceeds_Bgc {
  background-color: #6391f3c2;
}
.isProceeds_Bgc {
  background-color: #409eff;
}
// input type为number，只输入数字
/deep/.numrule input::-webkit-outer-spin-button,
/deep/.numrule input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
/deep/.numrule input[type='number'] {
  -moz-appearance: textfield;
}

// 还原 input 组件禁用后的置灰效果
/deep/.el-input.is-disabled .el-input__inner {
  background-color: #f5f7fa !important;
  border-color: #e4e7ed !important;
  color: #c0c4cc !important;
  cursor: not-allowed;
}
.project-jincome-top {
  margin-bottom: 10px;
  color: #303133;
  display: flex;
  justify-content: space-between;
}
.project-jincome-fw {
  font-size: 18px;
  font-weight: 700;
}
.deadline-color-red {
  color: red;
}
.deadline-color-green {
  color: green;
}
/deep/.el-button.is-disabled.el-button--text {
  background-color: #409eff;
}

.payment-dialog {
  .el-dialog__body {
    padding: 20px 30px;
  }

  .el-dialog__header {
    padding: 15px 20px;
    border-bottom: 1px solid #eee;

    .el-dialog__title {
      font-size: 16px;
      font-weight: 600;
    }
  }

  .payment-form {
    .el-form-item__label {
      font-weight: 500;
      color: #606266;
    }

    .el-input__inner {
      height: 36px;
      line-height: 36px;
    }

    .el-textarea__inner {
      padding: 8px 12px;
    }

    .el-input-group__append {
      padding: 0 12px;
      background-color: #f5f7fa;
      color: #909399;
    }
  }

  .dialog-footer {
    padding-top: 10px;
    border-top: 1px solid #eee;
    text-align: right;

    .el-button {
      padding: 9px 20px;
      font-size: 13px;
    }
  }
}

// 输入框hover和focus状态的阴影效果
.el-input__inner:hover,
.el-textarea__inner:hover {
  border-color: #c0c4cc;
}

.el-input__inner:focus,
.el-textarea__inner:focus {
  border-color: #409eff;
  box-shadow: 0 0 0 2px rgba(64, 158, 255, 0.2);
}

.operation-container {
  margin: 15px 0;
  padding: 5px 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .add-payment-btn {
    padding: 8px 16px;
    font-size: 13px;
    border-radius: 4px;

    &:hover {
      opacity: 0.9;
      transform: translateY(-1px);
      transition: all 0.3s;
    }

    &:active {
      transform: translateY(0);
    }

    i {
      margin-right: 4px;
      font-weight: bold;
    }
  }
}
</style>
